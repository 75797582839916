import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface PieChartProps {
    data: number[];
    labels: string[];
    colors?: string[];
    height?: number;
    width?: number;
}

const PieChart: React.FC<PieChartProps> = ({ data, labels, colors = ['#775ffc', '#84d9fd', '#e6edf9'], height = 350, width = undefined }) => {
    const chartRef = useRef<any>(null);

    const options: ApexOptions = {
        chart: {
            type: 'pie',
        },
        labels: labels,
        legend: {
            show: false, // Hide the legend
        },
        tooltip: {
            enabled: false, // Disable tooltips
        },
        plotOptions: {
            pie: {
                expandOnClick: false, // Disable slice expansion on click
                dataLabels: {
                    offset: -5, // Adjust label position
                    minAngleToShowLabel: 10, // Minimum angle to show label
                },
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        fill: {
            colors: colors, // Custom colors for slices
        },
    };

    return (
        <Chart
            options={options}
            series={data}
            type="pie"
            height={height}
            width={width}
            ref={chartRef}
        />
    );
};

export default PieChart;
