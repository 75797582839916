import React, { useState } from 'react'
import DashboardHeader from '../../components/dashboard-components/header/DashboardHeader'
import { IoSearchOutline } from 'react-icons/io5'
import DateSelector from '../../components/dashboard-components/inputs/DateSelector'
import { IoFilter } from "react-icons/io5";
import AddMemberModal from '../../components/dashboard-components/modals/AddMemberModal'
import ListMembers from '../../components/dashboard-components/members/ListMembers'

const AllMembers = () => {
    const [openAddModal, setOpenAddModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({ startDate: '', endDate: '' });
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }
    return (
        <>
            <div className='all-donations'>
                <DashboardHeader subtitle={'Member'} title={"All Members"} />
                <div className='py-3'>
                    <div className='filter-bar'>
                        <div className='flex gap-2 items-center date-selector-div'>
                            <div className='flex gap-2 items-center'>
                                <button type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 flex items-center gap-3">
                                    <IoFilter /> Filter
                                </button>
                                <span className='mx-2 white-text text-sm font-semibold'>{selectedRows.length} row{selectedRows.length !== 1 ? 's' : ''} selected</span>
                            </div>
                            <div className='flex gap-2 items-center interval-selector'>
                                <div className='flex gap-2 items-center'>
                                    <span className='start'>Start Date</span>
                                    <DateSelector onChange={(date: any) => setFilter(prev => ({ ...prev, startDate: date }))} />
                                    <span className='to'>To</span>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <span className='end'>End Date</span>
                                    <DateSelector onChange={(date: any) => setFilter(prev => ({ ...prev, endDate: date }))} />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center add-div'>
                            <div className='text-nowrap'>
                                <button onClick={handleOpenAddModal} type="button" className="text-white bg-[#FF5841] hover:bg-gray-900 font-medium rounded-full text-sm px-4 py-2.5 me-2">+ Add Member</button>
                            </div>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <IoSearchOutline />
                                </div>
                                <input
                                    type="text"
                                    id="simple-search"
                                    className="bg-[#fff] border text-gray-900 text-sm  block w-full ps-10 p-2.5 rounded-[20px]"
                                    placeholder="Search"
                                    required
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-2'>
                    <ListMembers search={search} filter={filter} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                </div>
            </div>
            <AddMemberModal open={openAddModal} setOpen={setOpenAddModal} />
        </>
    )
}

export default AllMembers