import { Dialog, DialogTitle, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CiMail } from 'react-icons/ci';
import { RiCloseCircleFill } from "react-icons/ri";

export interface SimpleDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddMemberModal: React.FC<SimpleDialogProps> = ({ open, setOpen }) => {
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [designation, setDesignation] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setFullname('');
        setEmail('');
        setNumber('');
        setDesignation('');
    }, [open])

    return (
        <Dialog onClose={handleClose} sx={{
            // backdropFilter: "blur(5px) sepia(5%)",
            "& .MuiDialog-paper": {
                borderRadius: "20px",
            },
        }} open={open}>
            <DialogTitle>
                <div className='flex items-center justify-between'>
                    <span className='font-bold'>Add Member</span>
                    <span className='cursor-pointer text-[#72747A]' onClick={handleClose}><RiCloseCircleFill size={25} /></span>
                </div>

            </DialogTitle>
            <hr className='border-[#a4a6aa]' />
            <form >
                <div className="px-4 py-3">
                    <div className="row">
                        <div className="signup-input">
                            <label className="input-label">Full Name</label>
                            <input
                                type="text"
                                name="fullname"
                                placeholder="Enter full name"
                                className="form-control"
                                required
                                value={fullname}
                                onChange={e => setFullname(e.target.value)}
                            />
                        </div>
                        <div className="signup-input-with-icon">
                            <label className="input-label">Email Address</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter email address"
                                className="form-control"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <CiMail className="input-icon" />
                        </div>
                        <div className="signup-input">
                            <label className="input-label">Contact Numbert</label>
                            <input
                                type="text"
                                name="number"
                                placeholder="+1 000-1231574"
                                className="form-control"
                                required
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                            />
                        </div>
                        <div className="signup-input">
                            <label className="input-label">Designation</label>
                            <input
                                type="text"
                                name="designation"
                                placeholder="Ex. Sr Designer"
                                className="form-control"
                                required
                                value={designation}
                                onChange={e => setDesignation(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex justify-center py-3">
                        <button
                            type="submit"
                            className="ring-offset-background focus-visible:ring-ring w-50 inline-flex h-[45px]  items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2  font-medium text-white  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                            style={{ fontWeight: "700", fontSize: "14px" }}
                        >
                            Send Invite
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}

export default AddMemberModal