import React from 'react'
import DashboardHeader from '../../components/dashboard-components/header/DashboardHeader'
import OutlinedButton from '../../components/buttons/OutlinedButton'
import avatar from '../../imgs/Avatar2x.png'
import FillButton from '../../components/buttons/FillButton'

const Administration = () => {
    return (<>
        <div className='user-account min-h-[calc(100vh_-_50px)]'>
            <DashboardHeader subtitle={'Administration'} title={"User Account"} />
            <div className='py-2 md:py-3 lg:max-w-[90vh]'>
                <div className='my-2 bg-white rounded-[10px] md:rounded-[20px] p-4'>
                    <span className='text-xl font-bold'>Profile</span>
                    <div className='py-4 flex justify-between items-center'>
                        <div className='md:flex md:gap-3 md:items-center'>
                            <span><img src={avatar} alt='avatar' className='h-[80px]' /></span>
                            <span className='font-bold'>Riche roabs</span>
                        </div>
                        <div>
                            <OutlinedButton label='Edit profile' />
                        </div>
                    </div>
                </div>
                <div className='mt-4 bg-white rounded-[10px] md:rounded-[20px] p-4'>
                    <span className='flex items-center justify-between'><span className='text-xl font-bold'>Personal Details</span><span className='text-[#ff5841] font-bold'>Edit</span></span>
                    <div className='py-2 w-full'>
                        <div className="mt-2 md:grid md:grid-cols-2 gap-3">
                            <div className="signup-input">
                                <label className="input-label">Full Name</label>
                                <input
                                    type="text"
                                    name="fullname"
                                    placeholder="Riche roabs"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="signup-input">
                                <label className="input-label">Email Address</label>
                                <input
                                    type="text"
                                    name="fullname"
                                    placeholder="Richeroabs@gmail.com"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="signup-input">
                                <label className="input-label">Contact Number</label>
                                <input
                                    type="text"
                                    name="fullname"
                                    placeholder="+1 985-7894512"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="signup-input">
                                <label className="input-label">Designation</label>
                                <input
                                    type="text"
                                    name="fullname"
                                    placeholder="Sr. Manager"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className='flex justify-end gap-2 mt-3'>
                            <OutlinedButton label='Cancel' />
                            <FillButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <span className='text-2xl font-extrabold title white-text'>Payments Settings</span>
        </div>
    </>
    )
}

export default Administration