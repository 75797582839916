import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import logo from "../../imgs/logo-dark.png";
import hamburger from "../../imgs/hamburger.png";
import { Link, NavLink } from "react-router-dom";
import Sidebar from './Sidebar';
import { Tooltip } from "react-tooltip";

interface navbarProps {
  setIssidebaropen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<navbarProps> = ({ setIssidebaropen }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const navbarRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIssidebaropen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node) &&
        isSidebarOpen
      ) {
        setIsSidebarOpen(false);
        setIssidebaropen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen, setIssidebaropen]);

  return (
    <>
      <nav
        ref={navbarRef}
        className={`menu-container ${isSidebarOpen ? "background-blur-effect" : ""
          }`}
      >
        <div>
          <NavLink to="/" >
            <img src={logo} alt="My Awesome Website" className="logo" />
          </NavLink>
        </div>
        <div className="menu">
          <Link to="/"  className={`menu-item ${activeMenuItem === 'home' || activeMenuItem === '' ? 'active' : ''}`}  onClick={() => { setActiveMenuItem('home'); }}>
            Home
          </Link>
          <Link to="/#product"  className={`menu-item ${activeMenuItem === 'product' ? 'active' : ''}`} onClick={() => {setActiveMenuItem('product'); }}>
            Our Product
          </Link>
          <Link to="/#subscription"  className={`menu-item ${activeMenuItem === 'subscription' ? 'active' : ''}`} onClick={() => {  setActiveMenuItem('subscription');}}>
            Subscription
          </Link>
          <Link to="/#testimonial" className={`menu-item ${activeMenuItem === 'testimonial' ? 'active' : ''}`} onClick={() => { setActiveMenuItem('testimonial'); }} >
            Testimonial
          </Link>
          <NavLink to="/give" className="menu-item" onClick={() => { setActiveMenuItem('give'); }}>
            Give
          </NavLink>
        </div>
        <button onClick={toggleSidebar} className="toggle-btn">
          <img src={hamburger} alt="hamburger" />
        </button>

        <div className="login-btn">
          <button data-tooltip-id="login-tooltip" data-tooltip-content="Interested in a closer look? Request a demo or gain early access now!" className="ring-offset-background focus-visible:ring-ring inline-flex h-[45px] w-[125%] items-center justify-center whitespace-nowrap rounded-full bg-gray-300 text-gray-600 px-4 py-2 text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" style={{ cursor: "default" }}>
            Log In
          </button>


          <Tooltip id="login-tooltip" place="bottom" style={{ borderRadius: "8px" }} />

        </div>
      </nav>
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default Navbar;
