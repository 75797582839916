import React, { useState } from 'react';
import { Box, Step, StepLabel, Stepper, StepIconProps, styled, Divider } from '@mui/material';
import { RiOrganizationChart } from 'react-icons/ri';
import { FaUser } from 'react-icons/fa';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './transitions.css';  // Import the CSS file for transitions
import { CiMail } from 'react-icons/ci';

interface CustomStepIconProps extends StepIconProps { }

const CustomStepIcon: React.FC<CustomStepIconProps> = (props) => {
    const { active, completed, icon } = props;

    const icons = [
        <RiOrganizationChart />,
        <FaUser />
    ];

    const StyledIcon = styled('div')({
        color: completed ? '#fff' : active ? '#fff' : '#80838Dcb', // green for completed, blue for active, grey for default
        display: 'flex',
        alignItems: 'center',
        backgroundColor: completed ? '#FF5841' : active ? '#FF5841' : '#F6F6F6',
        padding: '10px',
        fontSize: '25px',
        borderRadius: '10px'
    });

    return <StyledIcon>{icons[Number(icon) - 1]}</StyledIcon>;
};

const steps = [
    { label: 'Organization Setup', subtitle: 'Step 1' },
    { label: 'User Setup', subtitle: 'Step 2' },
];

const OnBording: React.FC = () => {
    const [currStep, setCurrStep] = useState(0);

    const handleNextStep = () => {
        setCurrStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setCurrStep((prevStep) => prevStep - 1);
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <section className="w-full container py-3">
                <div className="onboarding-container container md:px-12">
                    <div className='flex justify-center mb-6'>
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',  // Mobile
                                    sm: '50%',   // Tablet
                                    md: '35%',   // PC
                                },
                            }}
                        >
                            <Stepper activeStep={currStep} alternativeLabel sx={{
                                "& .MuiStepConnector-line": {
                                    borderColor: "#C6CAD3",
                                    borderTopWidth: "4px",
                                    minWidth: "30px",
                                    marginTop: "8px",
                                    marginInline: "2px"
                                },
                            }}>
                                {steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel StepIconComponent={CustomStepIcon}>
                                            <div>
                                                <div className="text-sm text-gray-500">{step.subtitle}</div>
                                                <div className='text-xl font-semibold'>{step.label}</div>
                                            </div>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                    <Divider color="#C6CAD3" sx={{ height: 2, marginBlock: "20px" }} />
                    <SwitchTransition>
                        <CSSTransition
                            key={currStep}
                            classNames="fade"
                            timeout={300}
                        >
                            <div>
                                {currStep === 0 && (
                                    <div className='mt-4'>
                                        <form>
                                            <div className="signup-input">
                                                <label className="input-label">Name Of Organization</label>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    placeholder="e.g. Trinity Church"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="signup-input">
                                                <label className="input-label">Street Address</label>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    placeholder="e.g. 100 Main St."
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className='sm:grid sm:grid-cols-2 gap-3'>
                                                <div className="signup-input">
                                                    <label className="input-label">Zip Code</label>
                                                    <input
                                                        type="text"
                                                        name="organization"
                                                        placeholder="e.g. 10001"
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                                <div className="signup-input">
                                                    <label className="input-label">City</label>
                                                    <input
                                                        type="text"
                                                        name="organization"
                                                        placeholder="e.g. New York City"
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='sm:grid sm:grid-cols-2 gap-3'>
                                                <div className="signup-input">
                                                    <label className="input-label">State</label>
                                                    <input
                                                        type="text"
                                                        name="organization"
                                                        placeholder="e.g. New York"
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                                <div className="signup-input">
                                                    <label className="input-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        name="organization"
                                                        placeholder="(555) 321 4567"
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="signup-input">
                                                <label className="input-label">Organization Website</label>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    placeholder="e.g. www.trinitychurch.com"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className='flex justify-center mt-8'>
                                                <button
                                                    type='button'
                                                    onClick={handleNextStep}
                                                    className="ring-offset-background focus-visible:ring-ring inline-flex h-[45px] items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-10 py-2 font-medium text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                                                    style={{ fontWeight: "700", fontSize: "14px" }}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                <div className={`${currStep === 1 ? 'mt-4 opacity-1 transition-opacity ease-in-out duration-500' : 'hidden opacity-0 transition-opacity ease-in-out duration-500'}`}>
                                    <form>
                                        <div className='sm:grid sm:grid-cols-2 gap-3'>
                                            <div className="signup-input">
                                                <label className="input-label">First Name</label>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    placeholder="e.g. John"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="signup-input">
                                                <label className="input-label">Last Name</label>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    placeholder="e.g. Smith"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="signup-input-with-icon">
                                            <label className="input-label">Email Address</label>
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="e.g. johnsmith@trinitychurch.com"
                                                className="form-control"
                                                required
                                            />
                                            <CiMail className="input-icon" />
                                        </div>
                                        <div className="signup-input">
                                            <label className="input-label">Role in Organization</label>
                                            <input
                                                type="text"
                                                name="organization"
                                                placeholder="e.g. Head Pastor"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="signup-input">
                                            <label className="input-label">Password</label>
                                            <input
                                                type="text"
                                                name="organization"
                                                placeholder="Enter password"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className='flex justify-center mt-8 gap-3'>
                                            <button
                                                type='button'
                                                onClick={handlePrevStep}
                                                style={{ border: "1.5px solid #FF5841", borderRadius: "25px", color: "#ff5841", backgroundColor: "#fff" }}
                                                className="px-16 h-[45px]"
                                            >
                                                Back
                                            </button>
                                            <button
                                                type='button'
                                                onClick={handleNextStep}
                                                className="ring-offset-background focus-visible:ring-ring inline-flex h-[45px] items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-16 py-2 font-medium text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                                                style={{ fontWeight: "700", fontSize: "14px" }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </section>
        </div>
    );
};

export default OnBording;
