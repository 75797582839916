import React from "react";

interface buttoninterface{
  label:string;
  onClick?:()=>void;
  disabled?:boolean;
}
const FillButton:React.FC <buttoninterface>= ({label,onClick,disabled}) => {
  return (
    <button onClick={onClick} disabled={disabled} className="ring-offset-background focus-visible:ring-ring  inline-flex h-[45px]  items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2  font-medium text-white  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" style={{fontWeight:"700",fontSize:"14px"}}>
      {label}
    </button>
  );
};

export default FillButton;
