import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    background: '#fff',
    width: 'auto',
    marginTop: '-10px',
    '& .MuiInputBase-root': {
        '&:focus, &:focus-within': {
            borderColor: '#000', // Change this to your desired border color
            borderWidth: '2px',
            borderStyle: 'solid',
        },
    },
}));
interface datpicker {
    onChange: any
}
const BasicDatePicker: React.FC<datpicker> = ({ onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    onChange={onChange}
                    className='CustomDatePicker custom-datepicker'
                    sx={{
                        background: "#fff",
                        width: "auto",
                        marginTop: "-10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#dcdcdc', // default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#ff5551', // border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ff5551', // border color when focused
                            },
                        },
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}

export default BasicDatePicker