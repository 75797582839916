import React from "react";
import RecurringPayments from "../../imgs/recurring-payments.png";
import FeatureEasyToSetup from "../../imgs/feature-easy-to-setup.png";
import FeatureSimpleUi from "../../imgs/feature-simple-ui.png";


const Features = () => {
  return (
    <div className="container py-5">
      <div className="d-flex flex-col  align-items-center">
        <div>
          <span className="text-[#7D818E] text-[18px] font-medium">
            FEATURES
          </span>
          {/* <span className="text-[#7D818E] text-[22px]"></span> */}
        </div>
        <h2 className="fs-1 font-bold leading-tight ">
          Get Amazing <span className="orange-font">Features</span>
        </h2>
      </div>
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:gap-16">
            <div className="py-9 px-9 text-center rounded-lg featurecard">
              <center>
                {" "}
                <img src={RecurringPayments} alt="feature" />
              </center>
              <h3 className="features-title">Recurring Payments</h3>
              <p className="features-desc">
                Streamline Tithes and Offerings with Scheduled Contributions
              </p>
            </div>
            <div className="py-9 px-9 text-center rounded-lg featurecard">
              <center>
                {" "}
                <img src={FeatureEasyToSetup} alt="feature" />
              </center>
              <h3 className="features-title">Easy to Use</h3>
              <p className="features-desc">
              We take the guesswork out of managing members and donations so you can focus on what truly matters—nurturing your community and fulfilling your mission.
              </p>
            </div>
            <div className="py-9 px-9 text-center rounded-lg featurecard">
              <center>
                {" "}
                <img src={FeatureSimpleUi} alt="feature" />
              </center>
              <h3 className="features-title">Everything in Sync</h3>
              <p className="features-desc">
                Quickly link <span className="text-[#088543] font-medium">QuickBooks</span>{" "}
                and organize donors with ease for streamlined organization management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
