import React from 'react';

const TransactionTable = () => {
  return (
    <div className="w-full overflow-auto">
      <table className="text-sm text-left w-full rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-md text-gray-400 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Reccuring
            </th>
            <th scope="col" className="px-6 py-3">
              Dates
            </th>
          </tr>
        </thead>
        <tbody className="text-dark font-bold">
          <tr className="bg-white dark:bg-gray-800">
            <th scope="row" className="px-6 py-2 whitespace-nowrap dark:text-white">
              Emily Rose
            </th>
            <td className="px-6 py-2">
              $450
            </td>
            <td className="px-6 py-2">
              $120
            </td>
            <td className="px-6 py-2">
              Jan 24, 2021
            </td>
          </tr>
          <tr className="bg-white dark:bg-gray-800">
            <th scope="row" className="px-6 py-2 whitespace-nowrap dark:text-white">
              Michael Jones
            </th>
            <td className="px-6 py-2">
              $150
            </td>
            <td className="px-6 py-2">
              $350
            </td>
            <td className="px-6 py-2">
              Jun 12, 2021
            </td>
          </tr>
          <tr className="bg-white dark:bg-gray-800">
            <th scope="row" className="px-6 py-2 whitespace-nowrap dark:text-white">
              Elizabeth Taylor
            </th>
            <td className="px-6 py-2">
              $200
            </td>
            <td className="px-6 py-2">
              $200
            </td>
            <td className="px-6 py-2">
              Aug 18, 2021
            </td>
          </tr>
          <tr className="bg-white dark:bg-gray-800">
            <th scope="row" className="px-6 py-2 whitespace-nowrap dark:text-white">
              John Brown
            </th>
            <td className="px-6 py-2">
              $350
            </td>
            <td className="px-6 py-2">
              $150
            </td>
            <td className="px-6 py-2">
              March 26, 2021
            </td>
          </tr>
          <tr className="bg-white dark:bg-gray-800">
            <th scope="row" className="px-6 py-2 whitespace-nowrap dark:text-white">
              Marie Garcia
            </th>
            <td className="px-6 py-2">
              $120
            </td>
            <td className="px-6 py-2">
              $450
            </td>
            <td className="px-6 py-2">
              July 05, 2021
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;