import React from "react";
import "./App.css";
import "./index.css";
import "./dashboardstyle.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./pages/Index";
import MainLayout from "./pages/Layout/MainLayout";
import { Give } from "./pages/Give";
import ScrollToTop from "./components/common/ScrollToTop";
import DashboardLayout from "./pages/Layout/DashboardLayout";
import MainDashboard from "./pages/dashboard/MainDashboard";
import AllDonations from "./pages/dashboard/AllDonations";
import AllMembers from "./pages/dashboard/AllMembers";
import Administration from "./pages/dashboard/Administration";
import Faqs from "./pages/dashboard/Faqs";
import OnBording from "./pages/OnBording";
import Verification from "./pages/Verification";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Index />} />
          <Route path="give" element={<Give />} />
          <Route path="onboarding" element={<OnBording />} />
          <Route path="before-verification" element={<Verification />} />
        </Route>
        <Route path="/demo" element={<DashboardLayout />}>
          <Route index element={<MainDashboard />} />
          <Route path="alldonations" element={<AllDonations />} />
          <Route path="allmembers" element={<AllMembers />} />
          <Route path="administration" element={<Administration />} />
          <Route path="support/faqs" element={<Faqs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
