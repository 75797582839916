import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, Divider } from '@mui/material';
import { IoMdEye } from 'react-icons/io';
import { RiPencilFill } from 'react-icons/ri';
import { MdDeleteForever } from 'react-icons/md';
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'var(--table-cell-bg)',
        color: 'var(--table-cell-text-color)',
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: '600',
        color: "var(--table-text-color)",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'var(--table-rows-bg-color)',
    },
    '&:nth-of-type(even)': {
        backgroundColor: 'var(--table-rows-bg-color)',
    },
    '&:last-child td, &:last-child th': {
        border: 1,
    },
    '& td, & th': {
        padding: '18px',
    },
}));

interface ListDonationsProps {
    selectedRows: any[];
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
    search: string;
    filter: {
        startDate: string;
        endDate: string;
    };
}

function createData(
    Fullname: string,
    Type: string,
    Date: string,
    Timestamp: string,
    Frequency: string,
    Recordedby: string,
    Status: string,
    Action: string,
    Aggregate: string
) {
    return { Fullname, Type, Date, Timestamp, Frequency, Recordedby, Status, Action, Aggregate };
}

const initialRows = [
    createData('Arthur Brown', 'Offerings', 'Jan, 04 2024', '21:45', 'Recurring', 'Jack Rus', 'Received', '', '$300'),
    createData('Emily Green', 'Donations', 'Jan, 05 2024', '12:30', 'One-time', 'Sarah Lee', 'Received', '', '$150'),
    createData('Michael Johnson', 'Pledges', 'Jan, 06 2024', '14:00', 'Monthly', 'Laura Smith', 'Pending', '', '$200'),
    createData('Jessica Taylor', 'Offerings', 'Jan, 07 2024', '16:45', 'One-time', 'Chris Brown', 'Received', '', '$250'),
    createData('David Williams', 'Donations', 'Jan, 08 2024', '09:15', 'Recurring', 'Anna Davis', 'Received', '', '$100'),
    createData('Sophia Martinez', 'Pledges', 'Jan, 09 2024', '11:00', 'Yearly', 'James Wilson', 'Completed', '', '$500'),
    createData('James Brown', 'Offerings', 'Jan, 10 2024', '13:30', 'One-time', 'Emily Clark', 'Received', '', '$350'),
    createData('Olivia Wilson', 'Donations', 'Jan, 11 2024', '15:00', 'Monthly', 'John Miller', 'Pending', '', '$120'),
    createData('William Johnson', 'Pledges', 'Jan, 12 2024', '17:15', 'Recurring', 'Emma Scott', 'Received', '', '$180'),
    createData('Ava Lee', 'Offerings', 'Jan, 13 2024', '08:45', 'One-time', 'Michael Lewis', 'Received', '', '$400'),
    createData('Ethan Harris', 'Donations', 'Jan, 14 2024', '10:30', 'One-time', 'Olivia Walker', 'Received', '', '$220'),
    createData('Isabella Adams', 'Pledges', 'Jan, 15 2024', '12:00', 'Yearly', 'Daniel Allen', 'Completed', '', '$600'),
    createData('Mason Miller', 'Offerings', 'Jan, 16 2024', '14:45', 'Recurring', 'Sophia Young', 'Received', '', '$270'),
    createData('Mia Wilson', 'Donations', 'Jan, 17 2024', '16:00', 'Monthly', 'David Martin', 'Pending', '', '$140'),
    createData('Liam Anderson', 'Pledges', 'Jan, 18 2024', '18:15', 'One-time', 'Chloe Thomas', 'Received', '', '$190'),
    createData('Charlotte Jackson', 'Offerings', 'Jan, 19 2024', '20:00', 'Recurring', 'Lucas White', 'Received', '', '$310'),
    createData('Benjamin Moore', 'Donations', 'Jan, 20 2024', '21:30', 'One-time', 'Mia Robinson', 'Received', '', '$230'),
    createData('Amelia Thompson', 'Pledges', 'Jan, 21 2024', '09:45', 'Yearly', 'Ethan Wright', 'Completed', '', '$550'),
    createData('Elijah Garcia', 'Offerings', 'Jan, 22 2024', '11:30', 'Monthly', 'Ava Lewis', 'Received', '', '$320'),
    createData('Harper Taylor', 'Donations', 'Jan, 23 2024', '13:00', 'Recurring', 'Oliver Hall', 'Pending', '', '$160'),
    createData('Alexander Nelson', 'Pledges', 'Jan, 24 2024', '15:15', 'One-time', 'Isabella Lee', 'Received', '', '$210'),
    createData('Ella Hernandez', 'Offerings', 'Jan, 25 2024', '17:00', 'Recurring', 'Benjamin Harris', 'Received', '', '$330'),
    createData('Jacob Wright', 'Donations', 'Jan, 26 2024', '18:30', 'One-time', 'Sophia Lewis', 'Received', '', '$250'),
];


export default function ListDonations({ search, filter, selectedRows, setSelectedRows }: ListDonationsProps) {
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    const filteredRows = useMemo(() => {
        return initialRows.filter(row => {
            const matchesSearch = row.Fullname.toLowerCase().includes(search.toLowerCase());
            const matchesDateRange = (!filter.startDate || new Date(row.Date) >= new Date(filter.startDate)) &&
                (!filter.endDate || new Date(row.Date) <= new Date(filter.endDate));
            return matchesSearch && matchesDateRange;
        });
    }, [search, filter]);

    const paginatedRows = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return filteredRows.slice(startIndex, startIndex + rowsPerPage);
    }, [page, filteredRows]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: typeof initialRows[0]) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, row]);
        } else {
            setSelectedRows(selectedRows.filter((r) => r !== row));
        }
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedRows(filteredRows);
        } else {
            setSelectedRows([]);
        }
    };

    const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

    return (
        <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='center'>
                            <Checkbox
                                icon={<MdOutlineRadioButtonUnchecked size={20} />}
                                checkedIcon={<IoIosCheckmarkCircleOutline size={20} />}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                    '&.Mui-checked': {
                                        color: '#fb4616',
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Select all' }}
                                onChange={handleSelectAllChange}
                                checked={selectedRows.length === filteredRows.length && filteredRows.length > 0}
                            />
                        </StyledTableCell>
                        <StyledTableCell align='center'>Fullname</StyledTableCell>
                        <StyledTableCell align='center'>Type</StyledTableCell>
                        <StyledTableCell align='center'>Date</StyledTableCell>
                        <StyledTableCell align='center'>Timestamp</StyledTableCell>
                        <StyledTableCell align='center'>Frequency</StyledTableCell>
                        <StyledTableCell align='center'>Recordedby</StyledTableCell>
                        <StyledTableCell align='center'>Status</StyledTableCell>
                        <StyledTableCell align='center'>Action</StyledTableCell>
                        <StyledTableCell align='center'>Aggregate</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedRows.map((row) => (
                        <StyledTableRow key={row.Fullname}>
                            <StyledTableCell align='center'>
                                <Checkbox
                                    icon={<MdOutlineRadioButtonUnchecked size={20} />}
                                    checkedIcon={<IoIosCheckmarkCircleOutline size={20} />}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                        },
                                        '&.Mui-checked': {
                                            color: '#fb4616',
                                        },
                                    }}
                                    checked={selectedRows.includes(row)}
                                    onChange={(event) => handleCheckboxChange(event, row)}
                                />
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {row.Fullname}
                            </StyledTableCell>
                            <StyledTableCell align='center'>{row.Type}</StyledTableCell>
                            <StyledTableCell align='center'>{row.Date}</StyledTableCell>
                            <StyledTableCell align='center'>{row.Timestamp}</StyledTableCell>
                            <StyledTableCell align='center'>{row.Frequency}</StyledTableCell>
                            <StyledTableCell align='center'>{row.Recordedby}</StyledTableCell>
                            <StyledTableCell align='center'>{row.Status}</StyledTableCell>
                            <StyledTableCell align='center'>
                                <div className='flex gap-2 justify-center items-center table-action'>
                                    <span className='action-icons'><IoMdEye size={18} /></span>
                                    <span className='action-icons'><RiPencilFill size={18} /></span>
                                    <span className='action-icons'><MdDeleteForever size={18} /></span>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align='center'>{row.Aggregate}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <Divider />
            <div className='w-[100%] px-3'>
                <div className='my-[16px] flex justify-between items-center'>
                    <span>
                        <button
                            type="button"
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-3 py-2 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            Previous
                        </button>
                    </span>
                    <span className='text-md font-semibold'>Page {page} of {totalPages}</span>
                    <span>
                        <button
                            type="button"
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-3 py-2 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            disabled={page === totalPages}
                            onClick={() => setPage(page + 1)}
                        >
                            Next
                        </button>
                    </span>
                </div>
            </div>
        </TableContainer>
    );
}
