import React, { useEffect, useState } from 'react';

interface TotalStateCardProps {
    title: string;
    state: string;
    statetype: 'currency' | 'other'; // Define other types if needed
    substateDetails?: string;
    subdetails?: string;
    IconComponent: React.ComponentType<any>;
}

const TotalStatesCard: React.FC<TotalStateCardProps> = ({
    title,
    state,
    statetype,
    substateDetails,
    subdetails,
    IconComponent
}) => {
    return (
        <div className='totalstates hover:shadow-custom'>
            <div className='state-icon'>
                {IconComponent && <IconComponent size={25}/>}
            </div>
            <div className='flex flex-col justify-between'>
                <div className='text-sm sub-title'>{title}</div>
                <div className='text-xl white-text font-bold flex'>{statetype === 'currency' && <span>$</span>} <span>{state}</span></div>
                {subdetails && <span className='flex gap-1'>
                    <span className='green-text font-bold text-sm'>{substateDetails}</span>
                    <span className='text-[12px] sub-title'>{subdetails}</span>
                </span>}
            </div>
        </div>
    );
};

export default TotalStatesCard;
