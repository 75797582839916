import React, { useState } from 'react'
import DashboardHeader from '../../components/dashboard-components/header/DashboardHeader'
import { FaChevronRight } from "react-icons/fa6";

const Faqs = () => {
    const [activeQ, setActiveQ] = useState(0);
    const [openId, setOpenId] = useState(0);

    const toggleFaq = (id: number) => {
        setOpenId(openId === id ? 0 : id);
    };
    const faqs = [
        {
            id: 1,
            que: 'What is a Payment Gateway?',
            ans: 'A payment gateway is a technology used by merchants to accept debit or credit card purchases from customers. It securely transmits payment information from the customer to the payment processor and returns the payment approval or decline back to the merchant.'
        },
        {
            id: 2,
            que: 'Do I need to pay to Instapay even when there is no transaction going on in my business?',
            ans: 'No, you do not need to pay Instapay when there is no transaction happening. Instapay charges are applied only when a transaction is processed.'
        },
        {
            id: 3,
            que: 'What platforms does ACME payment gateway support?',
            ans: 'ACME payment gateway supports a variety of platforms, including online stores, mobile apps, and point-of-sale systems. It integrates with major e-commerce platforms like Shopify, WooCommerce, and Magento, as well as custom solutions.'
        },
        {
            id: 4,
            que: 'Does ACME provide international payments support?',
            ans: 'Yes, ACME payment gateway provides support for international payments, allowing you to accept transactions in multiple currencies from customers around the world.'
        },
        {
            id: 5,
            que: 'Is there any setup fee or annual maintenance fee that I need to pay regularly?',
            ans: 'ACME does not charge a setup fee, and there are no annual maintenance fees. However, transaction fees apply per transaction processed through the payment gateway.'
        }
    ];

    return (
        <>
            <div className='faqs-section min-h-[calc(100vh_-_50px)]'>
                <DashboardHeader subtitle={'Support'} title={"FAQ’s"} />
                <div className='my-3 faq-desktop'>
                    <div className='desktop-faq relative'>
                        <div className='faq-question-section'>
                            {faqs.map((faq, index) => {
                                return (
                                    <div
                                        onClick={e => setActiveQ(index)}
                                        key={faq.id}
                                        className={`faq-question ${activeQ === index ? 'active' : ''} ${index !== faqs.length - 1 ? 'border-b' : ''}`}
                                    >
                                        <span className='flex items-center gap-3'>
                                            <span><div className='active-dot'></div></span>
                                            <span>{faq.que}</span>
                                        </span>
                                        <div className={`${activeQ === index ? 'text-[#ff5741]' : ''}`}><FaChevronRight /></div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='faq-ans-section pr-4'>
                            <div className='font-extrabold mr-3'>{faqs[activeQ].que}</div>
                            <div className='mt-2 mr-3'>{faqs[activeQ].ans}</div>
                        </div>
                    </div>
                </div>
                <div className='faq-mobile hidden'>
                    <div className="max-w-3xl mx-auto mt-4 space-y-2">
                        {faqs.map((faq, index) => (
                            <div
                                key={faq.id}
                                className={`transition-all duration-200 bg-white  shadow-md shadow-blue-100/50 cursor-pointer hover:bg-gray-50 ${index !== faqs.length - 1 ? 'border-b' : ''}`}
                            >
                                <button
                                    type="button"
                                    onClick={() => toggleFaq(faq.id)}
                                    className="flex items-center text-start justify-between w-full px-4 py-3 sm:p-6 rounded-sm"
                                >
                                    <span className="flex text-md font-semibold text-black">{faq.que}</span>
                                    <span><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                        stroke="currentColor"
                                        className={`w-4 h-4 text-gray-400 transition-transform ${openId === faq.id ? 'transform rotate-180' : ''}`}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg></span>
                                </button>
                                <div
                                    id={`answer${faq.id}`}
                                    style={{ display: openId === faq.id ? 'block' : 'none' }}
                                    className="px-4 sm:px-6 pb-6"
                                >
                                    <p>{faq.ans}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <span className='text-2xl white-text font-extrabold title'>Contact Support</span>
            </div >
        </>
    )
}

export default Faqs