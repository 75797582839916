// MainLayout.tsx
import React, { useState } from "react";
import Navbar from "../../components/header/Navbar";
import { Footer } from "../../components/footer/Footer";
import { Outlet } from "react-router";

const MainLayout = () => {
  const [issidebaropen, setIssidebaropen] = useState(false);
  return (
    <div className="main-layout">
      <Navbar setIssidebaropen={setIssidebaropen} />

      <main
        className={`main-section ${issidebaropen ? "background-blur-effect" : ""
          }`}
      >
        <Outlet />
      </main>

      <Footer issidebaropen={issidebaropen} />
    </div>
  );
};

export default MainLayout;
