import React from "react";
import logo from "../../imgs/logo-light.png";
import { LuPhoneCall } from "react-icons/lu";
import { MdMailOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { TiSocialTwitter } from "react-icons/ti";
import { AiFillInstagram } from "react-icons/ai";
import { MdFacebook } from "react-icons/md";

interface footerinterface {
  issidebaropen: boolean;
}
export const Footer: React.FC<footerinterface> = ({ issidebaropen }) => {
  return (
    <footer
      className={`bg-[#1D1E25] text-white ${
        issidebaropen ? "background-blur-effect" : ""
      }`}
    >
      <div className="container md:px-5">
        <div className="py-8 md:flex md:items-start md:justify-between">
          <div className="mb-6 p-4 md:mb-0">
            <h2 className="mb-2 text-2xl font-bold">
              <img src={logo} alt="ShareSpark" />
            </h2>
            <p className="mb-4 mt-4 text-sm text-gray-300">
              Simplify Generosity: Effortless Giving for organization Members
              Anytime, Anywhere.
            </p>
            <h3 className="mb-2 text-lg font-semibold">FOLLOW US</h3>
            <div className="flex space-x-4">
              <div className="social-btn ">
                <TiSocialTwitter className="icon" />
              </div>
              <div className="social-btn ">
                <AiFillInstagram className="icon" />
              </div>
              <div className="social-btn ">
                <MdFacebook
                  className="icon"
                  onClick={() => {
                    // Go to Facebook link
                    window.open(
                      "https://www.facebook.com/profile.php?id=61560997345125",
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-6 p-4 md:mb-0 ">
            <h3 className="mb-2 text-lg font-semibold">CONTACT</h3>
            <ul className="mt-3 space-y-5">
              <li className="flex items-center space-x-2">
                <LuPhoneCall className="contact-icon" />

                <span className="text-sm">+1 (720) 507-8618</span>
              </li>
              <li className="flex items-center space-x-2">
                <MdMailOutline className="contact-icon" />
                <a href="mailto:info@altiro.io" className="text-sm">
                  info@altiro.io
                </a>
              </li>
              <li className="flex items-center space-x-2">
                <IoLocationOutline className="contact-icon" />
                <span className="text-sm">
                  2921 W 38th Ave #330, Denver, Colorado 80211 US
                </span>
              </li>
            </ul>
          </div>
          <div className="p-4">
            <h3 className="mb-2 text-lg font-semibold">OUR NEWSLETTER</h3>
            <p className="mb-4 mt-3 text-sm text-gray-400">
              Subscribe to our newsletter and get update in your inbox for
              future plan
            </p>
            <div className="flex flex-col space-y-4">
              <input
                className="bg-transparent ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-full text-[#5A5B63] px-4 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                placeholder="Enter email address"
                type="email"
              />
              <button className="orange-shadow-btn ring-offset-background focus-visible:ring-ring hover:bg-primary/90 inline-flex h-11 w-fit items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2 text-sm font-medium text-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <hr className="border-t-[2px] border-dashed	border-[#e3e3e3] mt-[-20px]"></hr>
        <div className="container text-[14px] py-2 mx-auto flex flex-col md:flex-row md:items-start md:justify-between">
          <span className="text-gray-100 md:mb-0 py-2">
            Copyright ©2024 Soften Theme by{" "}
            <span className="orange-font">ShareSpark</span>. All Rights Reserved
          </span>
          <div className="flex space-x-4 py-2">
            <a className="text-xs text-gray-100 hover:text-white" href="#">
              Privacy Policy
            </a>
            <a className="text-xs text-gray-100 hover:text-white" href="#">
              Terms and Condition
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
