import React from "react";

interface selectinterface{
    selectChange:(event: React.ChangeEvent<HTMLSelectElement>) => void,

}
const SelectField:React.FC<selectinterface>= ({selectChange}) => {
  return (
    <select className="fund-selection form-select" onChange={selectChange}>
      <option selected disabled>
         -- Select Fund --
      </option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
    </select>
  );
};

export default SelectField;
