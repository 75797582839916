import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const offset = 50;
        const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: yCoordinate - offset, behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
