import React from 'react'
import { IoSearchOutline } from "react-icons/io5";

const SearchBar = () => {
    return (
        <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <IoSearchOutline/>
        </div>
        <input type="text" id="simple-search" className="text-sm rounded-lgblock w-full ps-10 p-2.5 rounded-[20px]" placeholder="Search" required />
    </div>
    )
}

export default SearchBar