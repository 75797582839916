import React from 'react';

interface RadioButtonProps {
  options: string[];
  selectedOption: string;
  onSelect: (option: string) => void;
}

const RadioSelect: React.FC<RadioButtonProps> = ({ options, selectedOption, onSelect }) => {
  return (
    <div className='d-flex gap-2'>
      {options.map((option, index) => (
        <span className={`${selectedOption === option ? 'timeduration-radio-active' : 'timeduration-radio'}`} key={index}>
          <input
            type="radio"
            id={`option${index}`}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => onSelect(option)}
            style={{ display: 'none' }} // Hide the actual radio button
          />
          <label  htmlFor={`option${index}`} className="radio-label" style={{cursor:"pointer"}}>{option}</label>
        </span>
      ))}
    </div>
  );
}

export default RadioSelect