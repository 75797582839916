import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import charticon from '../../../imgs/chart/chart-icon.png';

interface ChartData {
    name: string;
    data: number[];
}

interface TrafficChartChartProps {
    data: ChartData[];
    categories: string[];
}

const TrafficChart: React.FC<TrafficChartChartProps> = ({ data, categories }) => {
    const chartRef = useRef<any>(null);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            height: 200,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${charticon}" alt="Chart Icon" style="height: 40px; width: 40px;" />`, // Adjust height and width here
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: true,
                    customIcons: [] // Add custom icons if needed
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%', // Set the bar width
                borderRadius: 8, // For rounded corners
                borderRadiusApplication: 'end', // Top corners only
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                style: {
                    fontWeight: 700,
                    fontSize: '12px'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        legend: {
            show: false,
            position: 'top',
        },
        grid: {
            show: false,
        },
        yaxis: {
            show: false,
            min: 0,
        },
        fill: {
            opacity: 1,
            colors: ['#ff5841'] // Example custom colors for series
        },
        dataLabels: {
            enabled: false, // Disable data labels
        }
    };

    return (
        <Chart
            options={options}
            series={data}
            type="bar"
            // height={}
            ref={chartRef}
        />
    );
};

export default TrafficChart;
