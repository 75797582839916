import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, Divider } from '@mui/material';
import { IoMdEye } from 'react-icons/io';
import { RiPencilFill } from 'react-icons/ri';
import { MdDeleteForever } from 'react-icons/md';
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--table-cell-bg)',
      color: 'var(--table-cell-text-color)',
      fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: '600',
      color: "var(--table-text-color)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: 'var(--table-rows-bg-color)',
  },
  '&:nth-of-type(even)': {
      backgroundColor: 'var(--table-rows-bg-color)',
  },
  '&:last-child td, &:last-child th': {
      border: 1,
  },
  '& td, & th': {
      padding: '18px',
  },
}));


interface ListDonationsProps {
  selectedRows: any[];
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
  search: string;
  filter: {
    startDate: string;
    endDate: string;
  };
}

function createData(
  FullName: string,
  EmailAddress: string,
  PhoneNumber: string,
  JoinedDate: string,
  Timestamp: string,
  Designation: string,
  Action: string
) {
  return { FullName, EmailAddress, PhoneNumber, JoinedDate, Timestamp, Designation, Action };
}

const initialRows = [
  createData('Alice Johnson', 'alice.johnson@example.com', '(123) 456-7890', 'Jan, 04 2024', '09:45', 'Member', ''),
  createData('Bob Smith', 'bob.smith@example.com', '(234) 567-8901', 'Jan, 05 2024', '10:30', 'Volunteer', ''),
  createData('Charlie Brown', 'charlie.brown@example.com', '(345) 678-9012', 'Jan, 06 2024', '11:15', 'Admin', ''),
  createData('Diana Prince', 'diana.prince@example.com', '(456) 789-0123', 'Jan, 07 2024', '12:00', 'Member', ''),
  createData('Edward Norton', 'edward.norton@example.com', '(567) 890-1234', 'Jan, 08 2024', '13:45', 'Staff', ''),
  createData('Fiona Green', 'fiona.green@example.com', '(678) 901-2345', 'Jan, 09 2024', '14:30', 'Member', ''),
  createData('George Wilson', 'george.wilson@example.com', '(789) 012-3456', 'Jan, 10 2024', '15:15', 'Volunteer', ''),
  createData('Hannah Adams', 'hannah.adams@example.com', '(890) 123-4567', 'Jan, 11 2024', '16:00', 'Member', ''),
  createData('Isaac Clarke', 'isaac.clarke@example.com', '(901) 234-5678', 'Jan, 12 2024', '17:45', 'Admin', ''),
  createData('Jasmine Lee', 'jasmine.lee@example.com', '(012) 345-6789', 'Jan, 13 2024', '18:30', 'Member', ''),
  createData('Kevin Brown', 'kevin.brown@example.com', '(123) 456-7890', 'Jan, 14 2024', '19:15', 'Staff', ''),
  createData('Laura Johnson', 'laura.johnson@example.com', '(234) 567-8901', 'Jan, 15 2024', '20:00', 'Volunteer', ''),
  createData('Michael Davis', 'michael.davis@example.com', '(345) 678-9012', 'Jan, 16 2024', '21:00', 'Member', ''),
  createData('Nina Martinez', 'nina.martinez@example.com', '(456) 789-0123', 'Jan, 17 2024', '22:15', 'Admin', ''),
  createData('Oscar Thompson', 'oscar.thompson@example.com', '(567) 890-1234', 'Jan, 18 2024', '23:00', 'Member', ''),
  createData('Paula White', 'paula.white@example.com', '(678) 901-2345', 'Jan, 19 2024', '00:30', 'Volunteer', ''),
  createData('Quincy Adams', 'quincy.adams@example.com', '(789) 012-3456', 'Jan, 20 2024', '01:15', 'Staff', ''),
  createData('Rita Green', 'rita.green@example.com', '(890) 123-4567', 'Jan, 21 2024', '02:00', 'Member', ''),
  createData('Steve Rogers', 'steve.rogers@example.com', '(901) 234-5678', 'Jan, 22 2024', '03:30', 'Admin', ''),
  createData('Tina Turner', 'tina.turner@example.com', '(012) 345-6789', 'Jan, 23 2024', '04:15', 'Volunteer', ''),
  createData('Ulysses Grant', 'ulysses.grant@example.com', '(123) 456-7890', 'Jan, 24 2024', '05:00', 'Member', ''),
  createData('Vera Wang', 'vera.wang@example.com', '(234) 567-8901', 'Jan, 25 2024', '06:45', 'Staff', ''),
  createData('William Adams', 'william.adams@example.com', '(345) 678-9012', 'Jan, 26 2024', '07:30', 'Member', ''),
];

export default function ListMembers({ search, filter, selectedRows, setSelectedRows }: ListDonationsProps) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const filteredRows = useMemo(() => {
    return initialRows.filter(row => {
      const matchesSearch = row.FullName.toLowerCase().includes(search.toLowerCase());
      const matchesDateRange = (!filter.startDate || new Date(row.JoinedDate) >= new Date(filter.startDate)) &&
        (!filter.endDate || new Date(row.JoinedDate) <= new Date(filter.endDate));
      return matchesSearch && matchesDateRange;
    });
  }, [search, filter]);

  const paginatedRows = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return filteredRows.slice(startIndex, startIndex + rowsPerPage);
  }, [page, filteredRows]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: typeof initialRows[0]) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r !== row));
    }
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(filteredRows);
    } else {
      setSelectedRows([]);
    }
  };

  const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align='center'>
              <Checkbox
                icon={<MdOutlineRadioButtonUnchecked size={20} />}
                checkedIcon={<IoIosCheckmarkCircleOutline size={20} />}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 18,
                  },
                  '&.Mui-checked': {
                    color: '#fb4616',
                  },
                }}
                inputProps={{ 'aria-label': 'Select all' }}
                onChange={handleSelectAllChange}
                checked={selectedRows.length === filteredRows.length && filteredRows.length > 0}
              />
            </StyledTableCell>
            <StyledTableCell align='center'>Full Name</StyledTableCell>
            <StyledTableCell align='center'>Email Address</StyledTableCell>
            <StyledTableCell align='center'>Phone Number</StyledTableCell>
            <StyledTableCell align='center'>Joined Date</StyledTableCell>
            <StyledTableCell align='center'>Timestamp</StyledTableCell>
            <StyledTableCell align='center'>Designation</StyledTableCell>
            <StyledTableCell align='center'>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((row) => (
            <StyledTableRow key={row.FullName}>
              <StyledTableCell align='center'>
                <Checkbox
                  icon={<MdOutlineRadioButtonUnchecked size={20} />}
                  checkedIcon={<IoIosCheckmarkCircleOutline size={20} />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                    '&.Mui-checked': {
                      color: '#fb4616',
                    },
                  }}
                  checked={selectedRows.includes(row)}
                  onChange={(event) => handleCheckboxChange(event, row)}
                />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.FullName}
              </StyledTableCell>
              <StyledTableCell align='center'>{row.EmailAddress}</StyledTableCell>
              <StyledTableCell align='center'>{row.PhoneNumber}</StyledTableCell>
              <StyledTableCell align='center'>{row.JoinedDate}</StyledTableCell>
              <StyledTableCell align='center'>{row.Timestamp}</StyledTableCell>
              <StyledTableCell align='center'>{row.Designation}</StyledTableCell>
              <StyledTableCell align='center'>
                <div className='flex gap-2 justify-center items-center table-action'>
                  <span className='action-icons'><IoMdEye size={18} /></span>
                  <span className='action-icons'><RiPencilFill size={18} /></span>
                  <span className='action-icons'><MdDeleteForever size={18} /></span>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Divider />
      <div className='w-[100%] px-3'>
        <div className='py-[16px] flex justify-between items-center'>
          <span>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-3 py-2 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </button>
          </span>
          <span className='text-md font-semibold'>Page {page} of {totalPages}</span>
          <span>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-3 py-2 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </span>
        </div>
      </div>
    </TableContainer>
  );
}
