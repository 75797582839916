import React from "react";
import basicimg from "../../imgs/basic.png";
import standardimg from "../../imgs/standard.png";
import premiumimg from "../../imgs/premium.png";
import { FaRegCheckCircle } from "react-icons/fa";
import SignUpForEarlyAccess from "../model/SignUpForEarlyAccess";

const Pricing = () => {
  return (
    <div className="pricing-section">
      <div className="container py-5">
        <div className="d-flex flex-col  align-items-center text-[#fff]">
          <div>
            <span className="text-[#fff] text-[17px] font-medium">
              PRICING PLAN
            </span>
          </div>
          <h1 className="fs-1 font-bold leading-tight ">
            Choose <span className="orange-font">Affordable Prices</span>
          </h1>
        </div>

        <div className="flex items-center justify-center py-5">
          <div className="cards-container space-y-6 md:flex md:items-center md:justify-center md:flex-wrap md:space-y-1 md:space-x-6 md:gap-y-6 md:gap-x-5 lg:flex-nowrap lg:gap-y-0">
            <article className="bg-white rounded-xl pricecard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 relative">
              <div className="pt-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-col justify-content-evenly">
                    <h1 className="orange-font plan-text">Basic Giving Platform</h1>
                    <div>
                      <span className="fs-1 font-bold">$0</span>
                      <span className="text-[#767A85] font-medium">/Month</span>
                    </div>
                  </div>
                  <div>
                    <img src={basicimg} alt="basic" className="h-[100px] w-[110px]" />
                  </div>
                </div>
              </div>
              <div className="plan-header-card">
                <p className="plan-desc-header">Basic analytics </p>
                <span className="text-gray-500"><span className="font-bold text-black">2.5% + $0.25</span> per transaction for credit/ debit cards</span>

                <p className="plan-desc-header mt-3">ACH/Bank </p>
                <span className="text-gray-500"><span className="font-bold text-black">0.8% + $0.25</span>  per transaction</span>

                <p className="plan-desc-header mt-3">American Express</p>
                <span className="text-gray-500"><span className="font-bold text-black">3.3% + $0.25</span> per transaction</span>
              </div>
              <div className="mt-4 text-[15px] leading-6 text-gray-500">
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Basic analytics.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons " /></span>
                  <span> Customizable donation pages.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Automatic email receipts.</span>
                </div>
              </div>
              <div className="purchase-btn">
                {/* <FillButton label="Purchase Now" /> */}
                <SignUpForEarlyAccess/>
              </div>
            </article>

            <article className="bg-white rounded-xl pricecard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 relative">
              <div className="pt-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-col justify-content-evenly">
                    <h1 className="orange-font plan-text">Enhanced Giving Platform</h1>
                    <div>
                      <span className="fs-1 font-bold">$29</span>
                      <span className="text-[#767A85] font-medium">/Month</span>
                    </div>
                  </div>
                  <div>
                    <img src={standardimg} alt="basic" className="h-[100px] w-[130px]" />
                  </div>
                </div>
              </div>
              <div className="plan-header-card">
                <p className="plan-desc-header">Basic analytics </p>
                <span className="text-gray-500"><span className="font-bold text-black">2.3% + $0.20</span> per transaction for credit/ debit cards</span>

                <p className="plan-desc-header mt-3">ACH/Bank </p>
                <span className="text-gray-500"><span className="font-bold text-black">0.7% + $0.20</span>  per transaction</span>

                <p className="plan-desc-header mt-3">American Express</p>
                <span className="text-gray-500"><span className="font-bold text-black">3.1% + $0.20</span> per transaction</span>
              </div>
              <div className="mt-4 text-[15px] leading-6 text-gray-500">
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>All Basic features.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Recurring donation management.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span> Text-to-give options.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons " /></span>
                  <span> Basic 24/7 email support.</span>
                </div>

              </div>
              <div className="purchase-btn">
                {/* <FillButton label="Purchase Now" /> */}
                <SignUpForEarlyAccess/>
              </div>
            </article>
            <article className="bg-white rounded-xl pricecard max-w-[350px] mx-auto w-[90vw] sm:w-full md:mx-0 relative">
              <div className="pt-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-col justify-content-evenly">
                    <h1 className="orange-font plan-text">Premium Giving Platform</h1>
                    <div>
                      <span className="fs-1 font-bold">$49</span>
                      <span className="text-[#767A85] font-medium">/Month</span>
                    </div>
                  </div>
                  <div>
                    <img src={premiumimg} alt="basic" className="h-[100px] w-[140px]" />
                  </div>
                </div>
              </div>
              <div className="plan-header-card">
                <p className="plan-desc-header">Basic analytics </p>
                <span className="text-gray-500"><span className="font-bold text-black">2.1% + $0.15</span> per transaction for credit/ debit cards</span>

                <p className="plan-desc-header mt-3">ACH/Bank </p>
                <span className="text-gray-500"><span className="font-bold text-black">0.5% + $0.15</span>  per transaction</span>

                <p className="plan-desc-header mt-3">American Express</p>
                <span className="text-gray-500"><span className="font-bold text-black">3.0% + $0.15</span> per transaction</span>
              </div>
              <div className="mt-4 text-[15px] leading-6 text-gray-500">
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>All Enhanced features.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Premium analytics dashboard.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons" /></span>
                  <span>Advanced donor engagement tools.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons" /></span>
                  <span>Integration capabilities.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span> Priority 24/7 support with phone and chat.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span> <FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Event and ticketing support.</span>
                </div>
                <div className="d-flex plan-check-list">
                  <span><FaRegCheckCircle size="" className="check-icons " /></span>
                  <span>Enhanced security features.</span>
                </div>
              </div>
              <div className="purchase-btn">
                {/* <FillButton label="Purchase Now" /> */}
                <SignUpForEarlyAccess/>
              </div>
            </article>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
