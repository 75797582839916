import React, { useState } from 'react';
import { IoLocationOutline } from 'react-icons/io5';

interface Address {
  addressLabel: string;
}
interface locationinput {
  setLocation: React.Dispatch<React.SetStateAction<string | "">>;
}

const LocationInput: React.FC<locationinput> = ({ setLocation }) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      setResults([]);
    }
    setLocation(inputValue)
    setQuery(inputValue);

    try {
      if (inputValue !== '') {
        const response = await fetch(
          `https://api.radar.io/v1/search/autocomplete?query=${encodeURIComponent(inputValue)}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_WEATHER_API_KEY || ''
            }
          }
        );
        const data = await response.json();
        setResults(data.addresses || []);
      }
    } catch (error) {
      // console.error('Error fetching autocomplete results:', error);
    }
  };

  const handleSelect = (address: Address) => {
    setSelectedAddress(address);
    setLocation(address.addressLabel);
    setQuery(address.addressLabel);
    setResults([]);
  };

  return (
    <div className="signup-input-with-icon  position-relative">
      <label className="input-label">Location</label>
      <input
        type="text"
        className="form-control"
        value={query}
        onChange={handleChange}
        placeholder="Enter location"
      />
      <IoLocationOutline className="input-icon" />

      {results.length > 0 &&
        <ul className="list-group mt-2 position-absolute w-[90%]" style={{ zIndex: 1000, height: "160px", overflowY: "scroll" }}>
          {results?.map((result, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleSelect(result)}
              style={{ cursor: 'pointer' }}
            >
              {result.addressLabel}
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default LocationInput;

