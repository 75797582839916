import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import charticon from '../../../imgs/chart/chart-icon.png';

interface ChartData {
    name: string;
    data: number[];
}

interface StackedColumnsChartProps {
    data: ChartData[];
    categories: string[];
}

const StackedColumnsChart: React.FC<StackedColumnsChartProps> = ({ data, categories }) => {
    const chartRef = useRef<any>(null);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            height: 200,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${charticon}" alt="Chart Icon" style="height: 40px; width: 40px;" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: true,
                    customIcons: []
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%',
                borderRadius: 10,
                borderRadiusApplication: 'end'
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                style: {
                    fontWeight: 700,
                    fontSize: '12px'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        legend: {
            show: false,
            position: 'top'
        },
        grid: {
            show: false
        },
        yaxis: {
            show: false,
            min: 0
        },
        fill: {
            opacity: 1,
            colors: ['#775ffc', '#84d9fd', '#e6edf9']
        },
        dataLabels: {
            enabled: false
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    plotOptions: {
                        bar: {
                            columnWidth: '30%'
                        }
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    }
                }
            },
            {
                breakpoint: 768,
                options: {
                    plotOptions: {
                        bar: {
                            columnWidth: '40%'
                        }
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '8px'
                            }
                        }
                    }
                }
            },
            {
                breakpoint: 480,
                options: {
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '6px'
                            }
                        }
                    }
                }
            }
        ]
    };

    return (
        <div className="chart-container">
            <Chart
                options={options}
                series={data}
                type="bar"
                height={350}
                ref={chartRef}
            />
        </div>
    );
};

export default StackedColumnsChart;
