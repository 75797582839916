import React from "react";

interface outlinedbuttoninterface{
  label:string;
  onClick?:()=>void;
  disabled?:boolean;
}
const OutlinedButton:React.FC <outlinedbuttoninterface>= ({label,onClick,disabled}) => {
  return (
    <button
    onClick={onClick}
    disabled={disabled}
    style={{border:"1.5px solid #FF5841",borderRadius:"25px",color:"#ff5841",backgroundColor:"#fff"}}
    className="px-3 h-[45px]"
  >
    {label}
  </button>
  
  );
};

export default OutlinedButton;
