import React, { useState } from 'react';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import FillButton from '../components/buttons/FillButton';
import { RiBankFill } from 'react-icons/ri';
import { IoMdPerson } from 'react-icons/io';
import { GiOrganigram } from 'react-icons/gi';
import { Divider } from '@mui/material';
import bankimg from '../imgs/vector/bank.png';
import organizationimg from '../imgs/vector/organization.png';
import primary_representationimg from '../imgs/vector/primary-representation.png';

const Verification = () => {
    const [currentStep, setCurrentStep] = useState(0); // State to track current step
    const [steps, setSteps] = useState([
        {
            id: 1,
            status: 'active',
            step: 'Bank Account Information'
        },
        {
            id: 2,
            status: 'inactive',
            step: 'Organization Verification Information'
        },
        {
            id: 3,
            status: 'inactive',
            step: 'Primary Representative Information'
        }
    ]);

    const icons = [
        <RiBankFill size={22} />,
        <IoMdPerson size={22} />,
        <GiOrganigram size={22} />
    ];

    // Function to handle click on a step
    const handleStepClick = (index: number) => {
        setCurrentStep(index);
        // Update statuses
        const updatedSteps = steps.map((step, i) => ({
            ...step,
            status: i === index ? 'active' : i < index ? 'done' : 'inactive'
        }));
        setSteps(updatedSteps);
    };

    return (
        <>
            <section className="w-full container py-5 pt-2">
                <div className="flex flex-col gap-2 sm:flex sm:flex-row sm:justify-between pb-4 items-center">
                    <span className="text-xl font-bold">
                        Complete all the steps to start receiving donations
                    </span>
                    <span className='flex justify-end w-full sm:w-auto'>
                        <FillButton label="Ready to receive donations" disabled={true} />
                    </span>
                </div>
                <div className="verification-container container py-4">
                    <div>
                        <div className="flex justify-center gap-3  md:pt-10 md:px-12">
                            {steps.map((step, index) => (
                                <div
                                    key={index}
                                    className='text-center flex flex-col gap-3 items-center w-[180px]'
                                    onClick={() => handleStepClick(index)}
                                >
                                    <span className={`font-semibold p-2 rounded-lg ${step.status === 'active' || step.status === 'done'
                                        ? 'bg-[#FF5841] text-white'
                                        : 'bg-[#F6F6F6] text-gray-500'
                                        }`}>
                                        {icons[index]}
                                    </span>
                                    <span className='font-bold'>{step.step}</span>
                                </div>
                            ))}
                        </div>
                        <Divider color="#C6CAD3" sx={{ height: 2, marginBlock: "20px" }} />
                        <div className='process-view py-10 text-center'>
                            {steps.map((step, index) => (
                                <div key={index} className={`process-step ${step.status === 'active' ? 'active' : 'hidden'}`}>
                                    {index === 0 &&
                                        <div className='relative'>
                                            <div className=' flex flex-col items-center justify-center'>
                                                <FillButton label="Enter Bank Account Number" disabled={false} />
                                                <span className='pt-4 pb-16 text-[#000] text-sm font-semibold'>All donations will be routed to your organization’s checking account</span>
                                            </div>
                                            <img src={bankimg} alt='' className='absolute right-10 bottom-[-10px]' />
                                        </div>
                                    }
                                    {index === 1 &&
                                        <div className='relative'>
                                            <div className=' flex flex-col items-center justify-center'>
                                                <FillButton label="Enter Organazation Verification Information" disabled={false} />
                                                <span className='pt-4 pb-16 text-[#000] text-sm font-semibold'>To ensure the safety of your organization and long the members that support it</span>
                                            </div>
                                            <img src={organizationimg} alt='' className='absolute right-10 bottom-[-10px]' />
                                        </div>
                                    }
                                    {index === 2 &&
                                        <div className='relative'>
                                            <div className=' flex flex-col items-center justify-center'>
                                                <FillButton label="Enter Representative Information" disabled={false} />
                                                <span className='pt-4 pb-16 text-[#000] text-sm font-semibold'>Financial institutions are required by federal law to verify the personal information
                                                    of the primary representative. </span>
                                            </div>
                                            <img src={primary_representationimg} alt='' className='absolute right-10 bottom-[-10px]' />
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Verification;
