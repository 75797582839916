import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import charticon from '../../../imgs/chart/chart-icon.png';

interface ChartData {
    name: string;
    data: number[];
}

interface ChartProps {
    data: ChartData[];
}

const LineChart: React.FC<ChartProps> = ({ data }) => {
    const chartRef = useRef<any>(null); // useRef with any type for flexible access

    const options: ApexOptions = {
        chart: {
            type: 'line',
            dropShadow: {
                enabled: true,
                top: 10,
                left: 0,
                blur: 5,
                color: '#c4cdff',
                opacity: 0.55
            },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${charticon}" alt="Chart Icon" style="height: 40px; width: 40px;" />`, // Adjust height and width here
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: true,
                    customIcons: [] // Add custom icons if needed
                }
            }
        },
        xaxis: {
            categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            labels: {
                show: true,
                style: {
                    fontWeight: 700,
                    fontSize: '12px',
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        stroke: {
            curve: 'smooth',
            colors: ['#FF5841'] // Line color
        },
        grid: {
            show: false,
        },
        yaxis: {
            show: false,
            min: 0,
        },
        tooltip: {
            enabled: true, // Enable tooltips on hover
            theme: 'dark', // Customize the theme of the tooltip
            x: {
                show: true, // Show x-axis tooltip
                format: 'MMM', // Customize the format of the tooltip date
            },
            y: {
                formatter: (value) => `$${value}`, // Customize the format of the tooltip value
            },
            marker: {
                show: false, // Hide the marker (dotted line) on hover
            },
        },
    };

    return (
        <div>
            <Chart options={options} series={data} type="line" height={350} ref={chartRef} />
        </div>
    );
};

export default LineChart;
