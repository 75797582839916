import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import ReviewCard from "./ReviewCard";
import review_1 from "../../imgs/testimonials/review_1.png";
import review_2 from "../../imgs/testimonials/review_2.png";

interface ReviewData {
  name: string;
  review: string;
  profile_img: string;
  organization: string;
}

const Testimonials = () => {
  const Data = [
    {
      name: "Ryan S",
      organization: "Holy Trinity Organization",
      profile_img: review_1,
      review:
        "Our organization is eagerly awaiting the launch of ShareSpark, excited for the transformative impact on our donations and community engagement. We're ready to embrace this innovative step forward, confident it will enhance our mission and simplify giving for everyone. The future is bright, and we can't wait to start this journey!",
    },
    {
      name: "Shannon R",
      organization: "St. Thomas Episcopal Organization",
      profile_img: review_2,
      review:
        "Can't wait for ShareSpark! It's a game-changer for our organization—simple, cool, and ready to boost our community vibe. We're all in and pumped to see the magic it'll bring!",
    },
  ];
  const [review, setReview] = useState(Data);

  // Function to group reviews into pairs
  const groupReviewsIntoPairs = (review: ReviewData[]): ReviewData[][] => {
    const pairs: ReviewData[][] = [];
    for (let i = 0; i < review.length; i += 2) {
      pairs.push(review.slice(i, i + 2));
    }
    return pairs;
  };

  const reviewPairs = groupReviewsIntoPairs(review);

  return (
    <div className="testimonials-section">
      <div className="container py-[80px]">
        <section>
          <div className="container px-4 md:px-6">
            <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]">
              <div className="space-y-4">
                <h1 className="font-medium fs-5  grey-font">
                  OUR TESTIMONIALS
                </h1>
                <div className="d-flex flex-col">
                  <div className="fs-1 font-bold">
                    What People Say{" "}
                    <div className="orange-font mt-[-10px]">About Us</div>
                  </div>
                </div>
                <p className="max-w-[600px]  grey-font  dark:text-gray-400">
                See what early supporters are Saying about their anticipation.
                </p>
                <div className="mt-5">
                  {/* <FillButton label="See All"/> */}
                </div>
              </div>
              <div className="mx-auto aspect-photo overflow-hidden rounded-xl">
                <div className="swiper-pc">
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper "
                  >
                    {reviewPairs.map((pair, index) => (
                      <SwiperSlide key={index}>
                        <div className="d-flex gap-3">
                          {pair.map((review, idx) => (
                            <ReviewCard
                              key={idx}
                              name={review.name}
                              review={review.review}
                              profile_img={review.profile_img}
                              organization={review.organization}
                            />
                          ))}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="swiper-mobile ">
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className=" mySwiper"
                    space-between="10"
                  >
                    {review &&
                      review?.map((review) => {
                        return (
                          <SwiperSlide>
                            <div className="d-flex gap-3 h-[90%]">
                              <ReviewCard
                                name={review.name}
                                review={review.review}
                                profile_img={review.profile_img}
                                organization={review.organization}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Testimonials;
