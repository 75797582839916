import React from 'react'
import DashboardHeader from '../../components/dashboard-components/header/DashboardHeader'
import TotalStatesCard from '../../components/dashboard-components/main-dashboard/TotalStatesCard'
import { FiBarChart2 } from "react-icons/fi";
import { FaDollarSign } from "react-icons/fa";
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { FaRegCalendar } from "react-icons/fa";
import { MdArrowDropUp } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import LineChart from '../../components/dashboard-components/graphs/LineChart';
import StackedColumnsChart from '../../components/dashboard-components/graphs/StackedColumnsChart';
import TransactionTable from '../../components/dashboard-components/main-dashboard/TransactionTable';
import TrafficChart from '../../components/dashboard-components/graphs/TrafficChart';
import { FaCaretDown } from "react-icons/fa6";
import PieChart from '../../components/dashboard-components/graphs/PieChart';
import { Divider } from '@mui/material';

const MainDashboard = () => {

    const chartData = [
        {
            name: 'Series 1',
            data: [50, 60, 30, 70, 25, 74, 65, 56],
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 4,
                opacity: 0.4,
                color: '#4B6AC5'
            }
        }
    ];
    const columnchartData = [
        { name: 'A', data: [30, 40, 25, 50, 49, 21, 70, 51, 49] },
        { name: 'B', data: [23, 12, 54, 61, 32, 56, 81, 19, 21] },
        { name: 'C', data: [62, 42, 19, 31, 56, 24, 39, 41, 15] }
    ];

    const categories = ['17', '18', '19', '20', '21', '22', '23', '24', '25'];

    const traffic = [
        { name: "user", data: [40, 30, 20, 50, 60, 40, 35] }
    ]
    const trafficCaterories = ['00', '04', '08', '12', '14', '16', '18'];

    const pieData = [20, 19, 61];
    const pieLabels = ['Building Fund', 'Offering', 'C'];
    const customColors = ['#FF5841', '#6AD2FF', '#EFF4FB'];

    return (
        <div className='main-dashboard'>
            <DashboardHeader subtitle={'Dashboard'} title={"Main Dashboard"} />
            <div className='py-3'>
                <div className='total_states grid md:grid-cols-3 lg:grid-cols-5 xlg:grid-cols-6 gap-3'>
                    <TotalStatesCard
                        title="Donations"
                        state="350.4"
                        statetype="currency"
                        IconComponent={BarChartRoundedIcon}
                    />
                    <TotalStatesCard
                        title="Repeating"
                        state="642.39"
                        statetype="currency"
                        IconComponent={FaDollarSign}
                    />
                    <TotalStatesCard
                        title="Total donor"
                        state="534"
                        statetype="other"
                        substateDetails="+23%"
                        subdetails="since last month"
                        IconComponent={VolunteerActivismRoundedIcon}
                    />
                    <TotalStatesCard
                        title="Your balance"
                        state="1000"
                        statetype="currency"
                        IconComponent={FaDollarSign}
                    />
                    <TotalStatesCard
                        title="Total donations"
                        state="2935"
                        statetype="other"
                        IconComponent={FileCopyRoundedIcon}
                    />
                </div>
                <div className='grid md:grid-cols-1 lg:grid-cols-2 gap-3 my-3 details-containers'>
                    <div className='donation-graph-container !sm:grid !sm:grid-cols-1 md:flex gap-2 hover:shadow-custom'>
                        <div className='info w-auto'>
                            <div className='grey-bg font-gray font-semibold text-sm flex text-nowrap p-2 rounded-full items-center gap-2 w-fit px-3'><span><FaRegCalendar /></span><span>This Month</span></div>
                            <div className='mt-8 flex flex-col text-nowrap'>
                                <span className='text-3xl white-text font-bold'>$37.5K</span>
                                <span className='font-gray font-bold text-[14px] text-nowrap flex'>Total Donations <span className='green-text text-nowrap flex items-center'><MdArrowDropUp /> +2.45%</span></span>
                            </div>
                            <div className='flex items-center gap-2 font-bold mt-4 green-text'>
                                <FaCircleCheck />  On track
                            </div>
                        </div>
                        <div className='graph w-[100%]'>
                            <LineChart data={chartData} />
                        </div>
                    </div>
                    <div className='weekly-graph-container hover:shadow-custom'>
                        <div className='info w-auto absolute'>
                            <span className='text-[22px] font-extrabold bg-white p-1 rounded-full'>Weekly Donation</span>
                        </div>
                        <div className='graph w-[100%]'>
                            <StackedColumnsChart data={columnchartData} categories={categories} />
                        </div>
                    </div>
                    <div className='show-transaction-container w-full overflow-x-auto hover:shadow-custom'>
                        <span className='text-[22px] font-extrabold rounded-full'>
                            Recent Transactions
                        </span>

                        <TransactionTable />
                    </div>
                    <div className='traffic-pie-grid'>
                        <div className='show-traffic-container hover:shadow-custom'>
                            <div className='flex justify-between'>
                                <div>
                                    <span className='font-bold text-sm sub-title'>Daily Traffic</span>
                                    <div>
                                        <span className='text-3xl font-extrabold '>2.579</span><span className='font-bold sub-title text-sm '> Visitors</span>
                                    </div>
                                </div>
                                <div>
                                    <span className='text-sm font-bold green-text text-nowrap flex items-center'><MdArrowDropUp size={20} /> +2.45%</span>
                                </div>
                            </div>
                            <TrafficChart data={traffic} categories={trafficCaterories} />
                        </div>
                        <div className='show-piechart-container hover:shadow-custom'>
                            <div className='flex justify-between'>
                                <div>
                                    <span className='font-bold text-md'>Your Pie Chart</span>
                                </div>
                                <div>
                                    <span className='text-sm font-bold sub-title text-nowrap flex items-center gap-1'>Monthly <FaCaretDown /></span>
                                </div>
                            </div>
                            <div className='flex justify-center'><PieChart data={pieData} labels={pieLabels} colors={customColors} height={220} width={220} /></div>
                            <div className='piechart-subdata'>
                                <div className='text-center'>
                                    <span style={{
                                        width: '10px', height: '10px', borderRadius: '50%', backgroundColor: customColors[0], display: 'inline-block', marginRight: '5px'
                                    }}></span><span className='font-bold sub-title'>{pieLabels[0]}</span>
                                    <div className='font-bold text-xl'>{pieData[0]}%</div>
                                </div>
                                <div className='border-l-2'></div>
                                <div className='text-center'>
                                    <span style={{
                                        width: '10px', height: '10px', borderRadius: '50%', backgroundColor: customColors[1], display: 'inline-block', marginRight: '5px'
                                    }}></span><span className='font-bold sub-title'>{pieLabels[1]}</span>
                                    <div className='font-bold text-xl'>{pieData[1]}%</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MainDashboard