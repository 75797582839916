import React, { useState } from "react";
import { useLocation } from "react-router";
import greyarrow from "../imgs/arrow-double-gray.png";
import giveimg from "../imgs/give.png";
import RadioSelect from "../components/give/RadioSelect";
import { CiDollar } from "react-icons/ci";
import SelectField from "../components/give/SelectField";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import mastercard1 from "../imgs/mastercard.png";
import mastercard2 from "../imgs/mastercard2.png";
import FillButton from "../components/buttons/FillButton";
import useTitle from "../components/hooks/useTitle";
import "../components/give/give.css";

export const Give = () => {
  useTitle("Give");
  const location = useLocation();
  const { pathname } = location;
  const cardList = [mastercard1, mastercard2];
  const [selectedOption, setSelectedOption] = useState<string>("One Time");
  const [selectedCard, setSelectedcard] = useState(1);
  const [amount, setAmount] = useState<string>("");
  // const cardList = ["mastercard", "mastercard2", "rupay"];
  //radio selection for timeperiod
  const options: string[] = ["One Time", "Weekly", "Bi-Weekly", "Monthly"];
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
  };
  // end radio selection for time period

  //select fund
  const [selectedOption1, setSelectedOption1] = useState<String>();
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption1(value);
  };
  //select fund

  //select card
  function cardprew(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (selectedCard > 1) {
      setSelectedcard(selectedCard - 1);
    }
  }
  function cardforw(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (selectedCard < cardList.length) {
      setSelectedcard(selectedCard + 1);
    }
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  return (
    <>
      <div className="path-header">
        <div className="hero-text text-5xl font-bold">Give</div>
      </div>
      <div>
        <section className="w-full py-5 md:py-24 lg:py-32">
          <div className="give-container container  md:px-6">
            <div className="grid items-center gap-6 lg:grid-cols-[1fr_600px] lg:gap-1  xl:grid-cols-[1fr_700px]">
              <div className="space-y-4">
                <form className="donation-form d-flex flex-column justify-content-center align-items-center ">
                  <div className="max-w-[358px]">
                    <div className="duration-container">
                      <RadioSelect
                        options={options}
                        selectedOption={selectedOption}
                        onSelect={handleOptionSelect}
                      />
                    </div>
                    <div className="input-amount mt-5">
                      <label className="input-label">Donation Amount</label>
                      <input
                        type="text"
                        name="amount"
                        value={amount}
                        onChange={(e) => handleAmountChange(e)}
                        placeholder="Enter donation amount"
                        className="form-control"
                      />
                      <b>
                        {" "}
                        <CiDollar className="doller-icon" />
                      </b>
                    </div>
                    <div>
                      <label className="input-label mt-3">Fund</label>
                      <SelectField selectChange={selectChange} />
                    </div>
                    <div>
                      <label className="input-label mt-3">Select Card</label>
                      <div className="card-selection">
                        <button
                          onClick={cardprew}
                          className={`${
                            selectedCard > 1 ? "arrow-enable" : "arrow-disable"
                          }`}
                        >
                          <FaArrowLeft />
                        </button>
                        <img src={cardList[selectedCard - 1]} alt="cards" />
                        <button
                          onClick={cardforw}
                          className={`${
                            selectedCard < cardList.length
                              ? "arrow-enable"
                              : "arrow-disable"
                          }`}
                        >
                          <FaArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      // onClick={onClick}
                      // disabled={disabled}
                      className="w-[350px] fw-700  inline-flex h-[52px]  items-center justify-center whitespace-nowrap rounded-full bg-[#FF5841] px-4 py-2 text-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                    >
                      Give ${amount}
                    </button>
                  </div>
                </form>
              </div>
              <div className="mx-auto aspect-photo overflow-hidden rounded-xl">
                <img src={giveimg} alt="" className="donation-img" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
