import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import logodark from '../../imgs/logo-dark.png';
import logolight from '../../imgs/logo-light.png';
import { RxDotFilled } from "react-icons/rx";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FaDollarSign, FaMoon } from "react-icons/fa";
import { BiSolidFile } from "react-icons/bi";
import { RiShieldUserFill } from "react-icons/ri";
import GroupsIcon from '@mui/icons-material/Groups';
import { Divider, useMediaQuery } from '@mui/material';
import { IoSettingsOutline } from "react-icons/io5";
import { MdContactSupport, MdOutlineNotificationsNone } from "react-icons/md";
import { NavLink, Outlet } from 'react-router-dom';
import { MdWindow } from "react-icons/md";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoMdInformationCircleOutline } from 'react-icons/io';
import avatar from '../../imgs/Avatar.png';

const drawerWidth = 250;

const DashboardLayout = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [submenu2Open, setSubmenu2Open] = useState(false);
    const [memberopen, setMemberOpen] = useState(false);

    const isMobile = useMediaQuery('(max-width: 768px)');

    const handleMobileDrawerToggle = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSubmenu2Click = () => {
        setSubmenu2Open(!submenu2Open);
    };

    const handleOpenMember = () => {
        setMemberOpen(!memberopen)
    }

    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme') || 'light');

    useEffect(() => {
        const observer = new MutationObserver(() => {
            setTheme(document.documentElement.getAttribute('data-theme') || 'light');
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-theme'] });

        return () => observer.disconnect();
    }, []);

    const drawer = (
        <div className='dashboard-layout' style={{ position: 'relative', height: '100%' }}>
            <Toolbar>
                <img src={theme === 'dark' ? logolight : logodark} alt="Logo" style={{ height: '40px', marginBlock: '30px' }} />
            </Toolbar>
            <List style={{ marginBottom: 'auto', marginInline: "15px" }}>
                <NavLink onClick={handleMobileDrawerToggle} to='/demo'>
                    <ListItem button>
                        <span className='flex w-full justify-between items-center my-2'>
                            <span className='flex gap-2 items-center'>
                                <MdWindow size={20} />
                                <span className='font-extrabold'>Dashboard</span>
                            </span>
                        </span>
                    </ListItem>
                </NavLink>
                <ListItem button onClick={handleDropdownClick} >
                    <span className='flex w-full justify-between items-center my-2'>
                        <span className='flex gap-2 items-center'>
                            <FaDollarSign size={20} />
                            <span className='font-extrabold'>Donations</span>
                        </span>
                        {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                    </span>
                </ListItem>
                <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavLink onClick={handleMobileDrawerToggle} to='/demo/alldonations'><ListItem button sx={{ pl: 4 }}>
                            <span className='text-sm flex w-full justify-between items-center '>
                                <span className='flex gap-1 items-center'>
                                    <RxDotFilled />
                                    <span className='font-extrabold'>All Donations</span>
                                </span>
                            </span>
                        </ListItem>
                        </NavLink>
                        <ListItem button sx={{ pl: 4 }} onClick={handleSubmenu2Click}>
                            <span className='text-sm flex w-full justify-between items-center'>
                                <span className='flex gap-1 items-center'>
                                    <RxDotFilled />
                                    <span className='font-extrabold'>Manage Donations</span>
                                </span>
                                {submenu2Open ? <RiArrowDropUpLine size={24} /> : <RiArrowDropDownLine size={24} />}
                            </span>
                        </ListItem>
                        <Collapse in={submenu2Open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button sx={{ pl: 6 }}>
                                    <span className='text-xs flex w-full justify-between items-center'>
                                        <span className='flex gap-1 items-center'>
                                            <RxDotFilled />
                                            <span className='font-extrabold'>Add Donation</span>
                                        </span>
                                    </span>
                                </ListItem>
                                <ListItem button sx={{ pl: 6 }}>
                                    <span className='text-xs flex w-full justify-between items-center'>
                                        <span className='flex gap-1 items-center'>
                                            <RxDotFilled />
                                            <span className='font-extrabold'>Recurring Set-Up</span>
                                        </span>
                                    </span>
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
                <ListItem button>
                    <span className='flex w-full justify-between items-center  my-2'>
                        <span className='flex gap-2 items-center'>
                            <BiSolidFile size={20} />
                            <span className='font-extrabold'>Reports</span>
                        </span>
                    </span>
                </ListItem>
                <ListItem button onClick={handleOpenMember}>
                    <span className='flex w-full justify-between items-center  my-2'>
                        <span className='flex gap-2 items-center'>
                            <GroupsIcon />
                            <span className='font-extrabold'>Members</span>
                        </span>
                        <span>
                            {memberopen ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </span>
                </ListItem>
                <Collapse in={memberopen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavLink onClick={handleMobileDrawerToggle} to='/demo/allmembers'><ListItem button sx={{ pl: 4 }}>
                            <span className='text-sm flex w-full justify-between items-center'>
                                <span className='flex gap-1 items-center'>
                                    <RxDotFilled />
                                    <span className='font-extrabold'>All Members</span>
                                </span>
                            </span>
                        </ListItem>
                        </NavLink>
                        <ListItem button sx={{ pl: 4 }}>
                            <span className='text-sm flex w-full justify-between items-center'>
                                <span className='flex gap-1 items-center'>
                                    <RxDotFilled />
                                    <span className='font-extrabold'>Donations Records</span>
                                </span>
                            </span>
                        </ListItem>
                    </List>
                </Collapse>
                <NavLink onClick={handleMobileDrawerToggle} to='/demo/administration'>
                    <ListItem button>
                        <span className='flex w-full justify-between items-center  my-2'>
                            <span className='flex gap-2 items-center'>
                                <RiShieldUserFill size={20} />
                                <span className='font-extrabold'>Administration</span>
                            </span>
                        </span>
                    </ListItem>
                </NavLink>
                <br></br>
                <Divider sx={{ borderColor: "#000" }} />
                <br></br>
                <NavLink onClick={handleMobileDrawerToggle} to='/demo/support/faqs'>
                    <ListItem button>
                        <span className='flex w-full justify-between items-center  my-2'>
                            <span className='flex gap-2 items-center'>
                                <MdContactSupport size={20} />
                                <span className='font-extrabold'>Support</span>
                            </span>
                            <span className="bg-[#FF5841] text-[#fff] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">24</span>
                        </span>
                    </ListItem>
                </NavLink>
                <ListItem button>
                    <span className='flex w-full justify-between items-center  my-2'>
                        <span className='flex gap-2 items-center'>
                            <IoSettingsOutline size={20} />
                            <span className='font-extrabold'>Settings</span>
                        </span>
                    </span>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }} className="dashboard-main">
            <div className='fixed z-50 flex py-2 justify-between items-center top-0 w-full sm:hidden bg-white '>
                <div className='flex items-center gap-2'><IconButton
                    className='open-drawer-btn'
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleMobileDrawerToggle}
                    sx={{ display: { sm: 'none' } }}
                >
                    <CgMenuRightAlt />
                </IconButton>
                    <span><img src={theme === 'dark' ? logodark : logolight} alt="Logo" style={{ height: '35px' }} /></span>
                </div>
                <div className='flex items-center gap-2 mr-2'>
                    <div className='text-[#28292c86]'><MdOutlineNotificationsNone size={26} /></div>
                    <div className=''><img src={avatar} alt='avatar' className='h-[30px]' /></div>
                </div>
            </div>

            <MuiDrawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </MuiDrawer>
            <MuiDrawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </MuiDrawer>
            <Box
                className='mt-3 main-container sm:mt-10'
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    marginLeft: { sm: `${drawerWidth}px` }, // Adjust margin for sidebar width
                    transition: 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms', // Optional: Add transition for smooth animation
                    zIndex: 1, // Ensure content is above the sidebar
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default DashboardLayout;
