import React, { useEffect, useState } from 'react';
import SearchBar from '../inputs/SearchBar';
import avatar from '../../../imgs/Avatar.png';
import { MdOutlineNotificationsNone } from "react-icons/md";
import { FaMoon, FaSun } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";

interface HeaderProps {
    subtitle: string;
    title: string;
}

const DashboardHeader: React.FC<HeaderProps> = ({ subtitle, title }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'light' : 'dark');
        localStorage.setItem('mode',isDarkMode ? 'light' : 'dark')
    };

    return (
        <div className='dashboard-header'>
            <div className='flex flex-col'>
                <span className='text-sm sub-title'>{subtitle}</span>
                <span className='text-2xl font-extrabold title'>{title}</span>
            </div>
            <div className='search-bar'>
                <div className="search w-full"><SearchBar /></div>
                <div><MdOutlineNotificationsNone size={26} /></div>
                <div onClick={toggleTheme} className='cursor-pointer'>
                    {isDarkMode ? <FaSun size={19} /> : <FaMoon size={19} />}
                </div>
                <div><IoMdInformationCircleOutline size={26} /></div>
                <div className='avatar'><img src={avatar} alt='avatar' /></div>
            </div>
        </div>
    );
}

export default DashboardHeader;
